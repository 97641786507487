.header
    position absolute
    top 0
    width 100%
    z-index 99
    transition $trans
    &.fixed
        position fixed
        background-color rgba(0,0,0,.2)
        animation fromTop .5s linear forwards
    &__wrapper
        display flex
        align-items center
        justify-content space-between
        flex-wrap wrap
        padding-top 20px

.logo
    display block
    max-width 200px
    width 100%

.menu
    display flex
    align-items center
    justify-content flex-start
    flex-wrap wrap
    &__item
        &:not(:last-child)
            margin-right 60px
    &__link
        color $white
        font-weight 500
        font-size 15px
        line-height 30px
        transition $trans
        padding 10px 0
        &:hover
            color $violet

.burger
    width 25px
    height 20px
    position relative
    display flex
    align-items center
    justify-content space-between
    flex-direction column
    div
        width 100%
        height 2px
        background-color $white
        transition $trans
    &.active
        position fixed
        right 15px
        z-index 99
        div
            &:nth-child(2)
                width 0

        
.side
    position fixed
    top 0
    width 100vw
    height 100%
    background-color rgba(8,14,79,.8)
    transform translateX(-100vw)
    transition $trans
    z-index 12
    &__wrapper
        width 100%
        height 100%
        display flex
        align-items center
        justify-content center
    ul
        display flex
        flex-direction column
    li
        margin-right 0 !important
        a
            font-size 24px
            line-height 39px
            display block
            padding 10px 0
    &.active
      transform translateX(0)  