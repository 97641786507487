.footer
    position relative
    &__bg
        position absolute
        top -40%
        left 0
        right 0
        width 100%
        height 500px
        background-size cover
        z-index 0
        background-position bottom
    &__logo
        display block
        max-width 200px
    &__wrapper
        display flex
        align-items flex-end
        justify-content space-between
        flex-wrap wrap
        margin-bottom 75px
    &__bottom
        display flex
        align-items center
        justify-content space-between
        flex-wrap wrap
        padding-bottom 25px
    &__copyright
        font-size 15px
        line-height 25px
        font-weight 500
        opacity .7
    &__menu
        display flex
        align-items center
        justify-content space-between
        li
            padding-right 30px
            position relative
            &:not(:last-child)
                margin-right 30px
                &:after
                    content ''
                    position absolute
                    right 0
                    bottom 0
                    top 0
                    margin auto
                    height 15px
                    width 1px
                    background-color $white
                    opacity .5
        a
            font-size 15px
            line-height 25px
            font-weight 500
            opacity .7
