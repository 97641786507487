@keyframes cycle
    from
        transform rotate(0deg)
    to
        transform rotate(360deg)
    
@keyframes fromTop
    0%
        top -200px
    100%
        top 0