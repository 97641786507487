body {
  position: relative;
  height: 100%;
  background: #fff;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  font-weight: normal;
  font-family: 'Gilroy', sans-serif;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
img {
  display: block;
  max-width: 100%;
}
input[type="text"],
input[type="email"],
input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}
input[type="number"]-webkit-inner-spin-button,
input[type="number"]-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
table {
  border-collapse: collapse;
  border-width: 0px;
  padding: 0px;
  margin: 0px;
}
html {
  height: 100%;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
}
input,
textarea {
  color: #fff;
  font-family: 'MADEOuterSans', sans-serif;
  border-radius: 0;
}
input[type="button"],
input[type="submit"],
button {
  cursor: pointer;
  font-family: 'MADEOuterSans', sans-serif;
  font-size: 20px;
  line-height: 30px;
}
td {
  margin: 0px;
  padding: 0px;
}
ul {
  list-style: none;
}
form {
  padding: 0px;
  margin: 0px;
}
a {
  color: #fff;
  transition: all 0.3s ease-out;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
a,
span,
div,
button {
  outline: none !important;
}
input[type=submit],
input[type=button],
button {
  -webkit-appearance: none;
  outline: none;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*before,
*after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.clearfixafter,
.wrapperafter,
.rowafter {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.loaded .main-wrapper {
  opacity: 0;
}
.icon-load {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  transform: translate3d(0, -100%, 0);
  transition: all 0.3s ease-out;
}
.loaded .icon-load {
  transform: translate3d(0, 0%, 0);
}
.sk-folding-cube {
  width: 40px;
  height: 40px;
  transform: rotateZ(45deg);
}
.sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  background: #fff;
  transform: scale(0.96);
}
.sk-folding-cube .sk-cubebefore {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $accent;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  transform: scale(0.97) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  transform: scale(0.97) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  transform: scale(0.97) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2before {
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3before {
  animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4before {
  animation-delay: 0.9s;
}
inputplaceholder,
textareaplaceholder {
  transition: opacity 0.2s ease-out;
}
inputfocusplaceholder,
textareafocusplaceholder {
  opacity: 0;
}
@-moz-keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@-o-keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/gilroy/Gilroy-Regular.eot");
  src: local("Gilroy-Regular"), url("../fonts/gilroy/Gilroy-Regular.eot?#iefix") format('embedded-opentype'), url("../fonts/gilroy/Gilroy-Regular.woff2") format('woff2'), url("../fonts/gilroy/Gilroy-Regular.woff") format('woff'), url("../fonts/gilroy/Gilroy-Regular.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/gilroy/Gilroy-Medium.eot");
  src: local("Gilroy-Medium"), url("../fonts/gilroy/Gilroy-Medium.eot?#iefix") format('embedded-opentype'), url("../fonts/gilroy/Gilroy-Medium.woff2") format('woff2'), url("../fonts/gilroy/Gilroy-Medium.woff") format('woff'), url("../fonts/gilroy/Gilroy-Medium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "MADEOuterSans";
  src: url("../fonts/madeouterfonts/MADEOuterSans.eot");
  src: local("MADEOuterSans"), url("../fonts/madeouterfonts/MADEOuterSans.eot?#iefix") format('embedded-opentype'), url("../fonts/madeouterfonts/MADEOuterSans.woff2") format('woff2'), url("../fonts/madeouterfonts/MADEOuterSans.woff") format('woff'), url("../fonts/madeouterfonts/MADEOuterSans.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "MADEOuterSans";
  src: url("../fonts/madeouterfonts/MADEOuterSans-Medium.eot");
  src: local("MADEOuterSans-Medium"), url("../fonts/madeouterfonts/MADEOuterSans-Medium.eot?#iefix") format('embedded-opentype'), url("../fonts/madeouterfonts/MADEOuterSans-Medium.woff2") format('woff2'), url("../fonts/madeouterfonts/MADEOuterSans-Medium.woff") format('woff'), url("../fonts/madeouterfonts/MADEOuterSans-Medium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "MADEOuterSans";
  src: url("../fonts/madeouterfonts/MADEOuterSans-Black.eot");
  src: local("MADEOuterSans-Black"), url("../fonts/madeouterfonts/MADEOuterSans-Black.eot?#iefix") format('embedded-opentype'), url("../fonts/madeouterfonts/MADEOuterSans-Black.woff2") format('woff2'), url("../fonts/madeouterfonts/MADEOuterSans-Black.woff") format('woff'), url("../fonts/madeouterfonts/MADEOuterSans-Black.ttf") format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@-moz-keyframes cycle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes cycle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes cycle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes cycle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-moz-keyframes fromTop {
  0% {
    top: -200px;
  }
  100% {
    top: 0;
  }
}
@-webkit-keyframes fromTop {
  0% {
    top: -200px;
  }
  100% {
    top: 0;
  }
}
@-o-keyframes fromTop {
  0% {
    top: -200px;
  }
  100% {
    top: 0;
  }
}
@keyframes fromTop {
  0% {
    top: -200px;
  }
  100% {
    top: 0;
  }
}
.footer {
  position: relative;
}
.footer__bg {
  position: absolute;
  top: -40%;
  left: 0;
  right: 0;
  width: 100%;
  height: 500px;
  background-size: cover;
  z-index: 0;
  background-position: bottom;
}
.footer__logo {
  display: block;
  max-width: 200px;
}
.footer__wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 75px;
}
.footer__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 25px;
}
.footer__copyright {
  font-size: 15px;
  line-height: 25px;
  font-weight: 500;
  opacity: 0.7;
}
.footer__menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer__menu li {
  padding-right: 30px;
  position: relative;
}
.footer__menu li:not(:last-child) {
  margin-right: 30px;
}
.footer__menu li:not(:last-child):after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  height: 15px;
  width: 1px;
  background-color: #fff;
  opacity: 0.5;
}
.footer__menu a {
  font-size: 15px;
  line-height: 25px;
  font-weight: 500;
  opacity: 0.7;
}
.header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
  transition: all 0.3s ease-out;
}
.header.fixed {
  position: fixed;
  background-color: rgba(0,0,0,0.2);
  animation: fromTop 0.5s linear forwards;
}
.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 20px;
}
.logo {
  display: block;
  max-width: 200px;
  width: 100%;
}
.menu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.menu__item:not(:last-child) {
  margin-right: 60px;
}
.menu__link {
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  transition: all 0.3s ease-out;
  padding: 10px 0;
}
.menu__link:hover {
  color: #978cf1;
}
.burger {
  width: 25px;
  height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.burger div {
  width: 100%;
  height: 2px;
  background-color: #fff;
  transition: all 0.3s ease-out;
}
.burger.active {
  position: fixed;
  right: 15px;
  z-index: 99;
}
.burger.active div:nth-child(2) {
  width: 0;
}
.side {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(8,14,79,0.8);
  transform: translateX(-100vw);
  transition: all 0.3s ease-out;
  z-index: 12;
}
.side__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.side ul {
  display: flex;
  flex-direction: column;
}
.side li {
  margin-right: 0 !important;
}
.side li a {
  font-size: 24px;
  line-height: 39px;
  display: block;
  padding: 10px 0;
}
.side.active {
  transform: translateX(0);
}
.main-wrapper {
  padding: 0 0 0 0;
  min-width: 320px;
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-image: url("../img/main/bg-main.png");
  background-size: cover;
  background-position: center;
}
.wrapper {
  min-width: 320px;
  max-width: 1200px;
  padding: 0 15px 0 15px;
  margin: 0 auto;
  position: relative;
}
p {
  font-size: 20px;
  line-height: 30px;
  font-family: 'Gilroy', sans-serif;
  color: #fff;
  font-weight: 400;
  font-weight: 500;
  color: rgba(255,255,255,0.5);
}
h1 {
  font-size: 30px;
  line-height: 45px;
  font-family: 'MADEOuterSans', sans-serif;
  color: #fff;
  font-weight: 400;
}
h2 {
  font-size: 50px;
  line-height: 65px;
  font-family: 'MADEOuterSans', sans-serif;
  color: #fff;
  font-weight: 900;
}
h3 {
  font-size: 20px;
  line-height: 30px;
  font-family: 'MADEOuterSans', sans-serif;
  color: #fff;
  font-weight: 500;
}
.main {
  min-width: 320px;
  width: 100%;
  flex-grow: 1;
  position: relative;
}
.custom-font {
  font-family: 'MadeOuterSans', sans-serif;
}
.op50 {
  opacity: 0.5;
}
.text-gradient {
  background: -webkit-linear-gradient(45deg, #978cf1, #f9ceda);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  font-size: 30px;
  line-height: 40px;
  text-transform: uppercase;
}
.section__title {
  font-size: 50px;
  line-height: 65px;
  font-weight: 900;
}
.section__title span {
  background: -webkit-linear-gradient(#fff, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.section-bg img {
  max-width: initial;
}
.nebula {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.nebula__bg {
  height: 100%;
}
.nebula video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: relative;
}
.intro {
  position: relative;
  margin-bottom: 120px;
}
.intro__wrapper {
  height: 100%;
}
.intro__inner {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding-top: 150px;
}
.intro__title {
  max-width: 523px;
  text-align: right;
  margin-bottom: 225px;
  width: 100%;
}
.intro__text {
  max-width: 477px;
  text-align: right;
  width: 100%;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  opacity: 0.5;
}
.orbit {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 11%;
  margin: auto;
  height: 85px;
  z-index: 10;
}
.orbit__inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.orbit__circle {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.orbit__letter div {
  font-size: 80px;
  line-height: 1;
  font-weight: 900;
}
.orbit__item {
  overflow: hidden;
}
.orbit__item:first-child {
  padding-right: 10px;
}
.orbit__item:last-child {
  padding-left: 10px;
}
.orbit__line {
  position: absolute;
}
.orbit__line.big {
  animation: cycle 20s linear infinite;
}
.orbit__line.small {
  animation: cycle 10s linear infinite;
}
.orbit__line svg {
  overflow: visible;
}
.btns {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 65px;
  z-index: 10;
  position: relative;
}
.btns.mt0 {
  margin-top: 0;
}
.btns--start {
  justify-content: flex-start;
}
.btns__item {
  font-size: 15px;
  font-family: 'MADEOuterSans', sans-serif;
  line-height: 25px;
  text-transform: uppercase;
  min-width: 256px;
  height: 55px;
  padding: 0 10px;
  color: #fff;
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 20px;
}
.btns__item.bordered {
  border-image: url("../img/main/btn-border-bg.svg") 27 23/50px 30px;
}
.btns__item.gradient {
  color: #061037;
  background: linear-gradient(to left, #f9ceda, #978cf1);
  border: none;
}
.btns__item.m0 {
  margin-left: 0;
  margin-right: 0;
}
.how {
  position: relative;
  margin-bottom: 25px;
}
.how__content {
  margin-bottom: 125px;
}
.how__lines {
  top: -80%;
  left: 0;
  right: 0;
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center 65%;
  position: absolute;
  z-index: 0;
}
.how__img {
  width: 100%;
  margin-bottom: 30px;
  position: relative;
}
.how__img img {
  position: relative;
  z-index: 3;
}
.how__planet {
  position: absolute;
  left: -8%;
  top: -70px;
  z-index: 2;
}
.how__planet:nth-child(2) {
  top: -150px;
  left: -160px;
}
.how__slogan {
  margin-top: 130px;
  font-size: 30px;
  line-height: 45px;
  max-width: 475px;
  position: relative;
}
.how__slogan span {
  position: relative;
  z-index: 3;
}
.how__info {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 520px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  text-align: center;
  z-index: 1;
}
.how__info span {
  margin-bottom: 40px;
  max-width: 470px;
}
.how__info img {
  position: absolute;
  top: -20%;
}
.how__info a {
  background: -webkit-linear-gradient(45deg, #978cf1, #f9ceda);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  display: inline-block;
}
.how__info a:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: -webkit-linear-gradient(45deg, #978cf1, #f9ceda);
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.features__content {
  margin-bottom: 95px;
}
.content p {
  margin-bottom: 35px;
}
.content h3 {
  margin-bottom: 5px;
}
.content ul {
  margin-bottom: 20px;
  padding-left: 20px;
}
.content li {
  font-size: 20px;
  line-height: 40px;
  font-weight: 500;
  list-style-image: url("../img/icons/list-planet-icon.svg");
  color: rgba(255,255,255,0.5);
}
.content__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.content__wrapper--start {
  justify-content: flex-start;
}
.content__wrapper--start .content__block {
  max-width: 470px;
}
.content__wrapper--start .content__block:first-child {
  margin-right: 130px;
}
.content__wrapper--end {
  align-items: flex-end;
}
.content__wrapper--end p {
  margin-bottom: 0;
}
.content__wrapper:not(:last-child) {
  margin-bottom: 95px;
}
.content__block {
  width: calc(50% - 15px);
}
.content__title {
  margin-bottom: 30px;
}
.merge {
  position: relative;
  margin-bottom: 125px;
}
.merge__title {
  font-size: 80px;
  line-height: 100px;
  text-align: right;
}
.merge__planet {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 400px;
  background-size: cover;
  position: absolute;
  z-index: 0;
}
.merge__info {
  position: relative;
  max-width: 355px;
  width: 100%;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'MADEOuterSans', sans-serif;
  font-weight: 900;
  z-index: 1;
}
.merge__info img {
  position: absolute;
}
.merge__info span {
  font-size: 30px;
  line-height: 40px;
  position: relative;
  z-index: 2;
}
.merge__img img {
  margin: -250px auto 0;
}
.merge .content__wrapper--end {
  margin-bottom: 25px;
}
.token {
  margin-bottom: 225px;
}
.token__title {
  margin-bottom: 40px;
}
.token__subtitle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.token__subtitle img {
  margin-right: 5px;
}
.token__wrapper {
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.token__line {
  margin-bottom: 30px;
}
.token__blocks {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.token__block {
  max-width: 470px;
  width: 100%;
}
.token__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.token__row:not(:last-child) {
  margin-bottom: 10px;
}
.token__row span,
.token__row strong {
  opacity: 0.7;
}
.token__row span {
  font-size: 20px;
  line-height: 35px;
}
.token__row div {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}
.table {
  margin-bottom: 170px;
  position: relative;
}
.table__bg {
  top: -5%;
  left: 0;
  right: 0;
  width: 100%;
  height: 400px;
  background-size: cover;
  position: absolute;
  z-index: 0;
}
.table__title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 45px;
}
.table table {
  width: 100%;
  font-size: 20px;
  line-height: 30px;
}
.table table td {
  text-align: center;
  vertical-align: middle;
  padding: 12px 0;
  position: relative;
  font-weight: bold;
  z-index: 2;
}
.table table td:first-child {
  font-weight: 600;
  opacity: 0.7;
}
.table table td:nth-child(2),
.table table td:last-child {
  text-transform: uppercase;
  font-size: 30px;
  line-height: 45px;
  font-family: 'MADEOuterSans', sans-serif;
  background: -webkit-linear-gradient(45deg, #978cf1, #f9ceda);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
}
.table table th {
  opacity: 0.7;
  padding-bottom: 10px;
  vertical-align: middle;
  text-align: center;
  z-index: 2;
  position: relative;
  font-weight: 400;
}
.table table tr:nth-child(2) td:nth-child(3),
.table table tr:nth-child(2) td:nth-child(4),
.table table tr:nth-child(2) td:nth-child(5) {
  font-weight: 700;
  font-weight: bold;
}
.table table tr:nth-child(2) td:nth-child(4),
.table table tr:nth-child(2) td:nth-child(5) {
  position: relative;
}
.table table tr:nth-child(2) td:nth-child(4):before,
.table table tr:nth-child(2) td:nth-child(5):before {
  content: url("../img/main/table-cell-line.svg");
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.table table tr:nth-child(9) td:nth-child(3) {
  font-weight: 700;
  font-weight: bold;
  position: relative;
  z-index: 1;
}
.table table tr:nth-child(2) td:nth-child(2),
.table table tr:nth-child(2) td:nth-child(6) {
  position: relative;
  z-index: 1;
}
.table table tr:nth-child(2) td:nth-child(2):after,
.table table tr:nth-child(2) td:nth-child(6):after {
  content: url("../img/main/table-bg.svg");
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}
.table table tr:nth-child(2) td:nth-child(4) {
  position: relative;
  z-index: 1;
}
.table table tr:nth-child(2) td:nth-child(4):after {
  content: url("../img/main/table-bg-mid.svg");
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}
.zilla {
  position: relative;
  margin-bottom: 155px;
  z-index: 10;
}
.zilla__planet {
  top: -5%;
  left: 0;
  right: 0;
  width: 100%;
  height: 440px;
  background-size: cover;
  position: absolute;
  z-index: 0;
}
.zilla__title {
  margin-bottom: 80px;
}
.zilla__subtitle {
  text-align: center;
  max-width: 530px;
  margin: auto;
  font-size: 30px;
  line-height: 45px;
  font-family: 'MADEOuterSans', sans-serif;
  margin-bottom: 110px;
}
.zilla__content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.zilla__content p {
  font-size: 15px;
  line-height: 25px;
}
.zilla__content p:not(:last-child) {
  margin-bottom: 20px;
}
.zilla__content strong {
  color: #fff;
}
.zilla__block {
  width: 50%;
}
.zilla__img img {
  margin: -150px auto 0;
}
.zilla__text {
  text-align: center;
  margin: auto;
  max-width: 240px;
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  margin: -40px auto 20px;
}
.zilla__socials {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 180px;
  margin: auto;
  z-index: 99;
}
.program {
  margin-bottom: 170px;
}
.program .content__block:last-child {
  position: relative;
}
.program .content__block:last-child:after {
  content: url("../img/main/program-planet.svg");
  position: absolute;
  top: -30%;
  right: -10%;
  z-index: 0;
}
.program__title {
  text-align: right;
  font-size: 50px;
  line-height: 65px;
  position: relative;
  z-index: 2;
}
.program .content__wrapper--start .content__block {
  max-width: inherit;
}
.program .content__wrapper--start .content__block:first-child {
  margin-right: 0;
}
.program__content p {
  font-size: 15px;
  line-height: 25px;
}
.program__text {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 45px;
  text-align: center;
  font-weight: bold;
}
.program__items {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.program__items div {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 35px;
}
.program__items img {
  margin-right: 15px;
}
.program__items span {
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
}
.apply .btns {
  margin-bottom: 45px;
}
.apply__title {
  max-width: 770px;
  margin-bottom: 30px;
}
.apply__text {
  max-width: 630px;
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 30px;
  opacity: 0.7;
}
.socials__title {
  margin-bottom: 45px;
  font-size: 30px;
  line-height: 45px;
  font-family: 'MADEOuterSans', sans-serif;
  text-align: right;
}
.socials__items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.socials__items a {
  display: block;
  max-width: 38px;
}
.socials__items a:not(:last-child) {
  margin-right: 70px;
}
@media (min-width: 1440px) {
  .zilla__planet {
    top: 8%;
    width: 105%;
  }
  .footer__bg {
    top: -100%;
  }
}
@media (max-width: 1024px) {
  .table__inner {
    overflow: scroll;
  }
  .table table {
    width: 1024px;
  }
}
@media (min-width: 1920px) {
  .how__lines {
    height: 500px;
    top: -110%;
  }
  .zilla__planet {
    top: 2%;
  }
  .footer__bg {
    top: -50%;
  }
}
@media (max-width: 880px) {
  .btns {
    flex-direction: column;
  }
  .btns a {
    width: 100%;
    margin: 0 0 30px;
  }
  .btns a:nth-child(1) {
    order: 2;
  }
  .btns a:nth-child(2) {
    order: 3;
  }
  .btns a:nth-child(3) {
    order: 1;
  }
}
@media (min-width: 769px) {
  .burger {
    display: none;
  }
  .merge__img .mobile {
    display: none;
  }
}
@media (max-width: 768px) {
  .header__menu {
    display: none;
  }
  .header__wrapper {
    padding: 10px 0;
  }
  .intro__title {
    font-size: 20px;
    line-height: 30px;
  }
  .intro__text {
    text-align: left;
    font-size: 18px;
    line-height: 29px;
    opacity: 1;
  }
  .intro__inner {
    align-items: flex-start;
  }
  .orbit {
    left: 0;
    top: -15%;
  }
  .orbit__letter div {
    font-size: 45px;
    line-height: auto;
  }
  .orbit__circle {
    width: 50px;
    height: 50px;
  }
  .orbit__circle img {
    max-width: 30px;
  }
  .merge {
    margin-bottom: 200px;
  }
  .merge__img .desktop {
    display: none;
  }
  .merge__img img {
    margin: -700px auto 0;
  }
  .merge__info {
    width: 100%;
    max-width: 100%;
  }
  .merge__info:first-child {
    margin-bottom: 500px;
  }
  .merge__content .content__wrapper:last-child {
    flex-direction: column;
  }
  .content__block {
    width: 100%;
    max-width: 100% !important;
    margin-right: 0 !important;
  }
  .token__wrapper,
  .token__subtitle {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .token__subtitle:not(:last-child) {
    margin-bottom: 20px;
  }
  .token__blocks {
    justify-content: center;
  }
  .token__block:first-child .token__row:last-child {
    margin-bottom: 10px;
  }
  .token__block:nth-child(2n) .token__row:first-child {
    display: none;
  }
  .zilla {
    margin-bottom: 50px;
  }
  .zilla__subtitle {
    margin-bottom: 50px;
  }
  .zilla__block {
    width: 100%;
  }
  .zilla__img img {
    margin: auto;
  }
  .program {
    margin-bottom: 100px;
  }
  .program__items {
    flex-direction: column;
  }
  .program__items div {
    width: 100%;
  }
  .program__items span {
    font-size: 15px;
    line-height: 25px;
  }
  .program__title {
    margin-bottom: 32px;
  }
  .program .content__wrapper {
    flex-direction: column-reverse;
  }
  .program .content__wrapper:not(:last-child) {
    margin-bottom: 0;
  }
  .socials__title {
    text-align: center;
  }
  .footer__wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer__logo {
    margin-bottom: 32px;
  }
  .footer__bottom {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer__copyright {
    order: 2;
    text-align: center;
  }
  .footer__menu {
    order: 1;
    flex-direction: column;
    padding-bottom: 32px;
  }
  .footer__menu li {
    margin-right: 0 !important;
    padding-right: 0;
    text-align: center;
    margin-bottom: 15px;
  }
  .footer__menu li:after {
    display: none;
  }
}
@media (max-width: 480px) {
  .nebula {
    top: -5%;
    left: -120%;
  }
  .nebula video {
    width: auto;
    height: auto;
  }
  .how__info img {
    top: 20%;
  }
  .merge__title {
    font-size: 60px;
    line-height: 80px;
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .merge__planet {
    top: 0%;
  }
  .merge .content__wrapper--end {
    flex-direction: column-reverse;
  }
  .section__title {
    font-size: 30px;
    line-height: 45px;
  }
  .token {
    margin-bottom: 100px;
  }
  .token__wrapper {
    flex-direction: column;
  }
  .token__title {
    text-align: center;
  }
  .table {
    margin-bottom: 100px;
  }
  .table__title {
    justify-content: center;
  }
  .zilla__title {
    text-align: center;
  }
  .program__title {
    font-size: 40px;
    line-height: 50px;
    text-align: center;
  }
  .program__content {
    margin-bottom: 50px;
  }
  .apply__title,
  .apply__text {
    text-align: center;
  }
  .socials__items a {
    margin-right: 0 !important;
  }
  .footer__wrapper {
    margin-bottom: 32px;
  }
}
