//main styles
.main-wrapper
	padding 0 0 0 0
	min-width $min-width
	width 100%
	position relative
	overflow hidden
	min-height 100%
	display flex
	flex-direction column
	justify-content flex-start
	background-image url('../img/main/bg-main.png')
	background-size cover
	background-position center

.wrapper
	min-width $min-width
	max-width $max-width
	padding 0 15px 0 15px
	margin 0 auto
	position relative

/* titles */
p
	font($base-size, $base-line, $text-color, $base-font, 400)
	font-weight 500
	color rgba(255,255,255,.5)

h1
	font($h1-size, $h1-line, $title-color, $custom-font, 400)

h2
	font($h2-size, $h2-line, $title-color, $custom-font, 900)

h3
	font($h3-size, $h3-line, $title-color, $custom-font, 500)


/* styles */
.main
	min-width $min-width
	width 100%
	flex-grow 1
	position relative

.custom-font
	font-family 'MadeOuterSans', sans-serif

.op50
	opacity .5

.text-gradient
	background -webkit-linear-gradient(45deg, #978CF1, #F9CEDA)
	-webkit-background-clip text
	-webkit-text-fill-color transparent
	font-weight 900
	font-size 30px
	line-height 40px
	text-transform uppercase

.section
	&__title
		font-size 50px
		line-height 65px
		font-weight 900
		span
			background -webkit-linear-gradient($white, $white)
			-webkit-background-clip text
			-webkit-text-fill-color transparent
	&-bg
		img
			max-width initial

.nebula
	position absolute
	top 0
	right 0
	left 0
	bottom 0
	width 100%
	height 100%
	&__bg
		height 100%
	video
		object-fit cover
		width 100%
		height 100%
		position relative

.intro
	position relative
	//padding-top 180px
	margin-bottom 120px
	//height 100vh
	&__wrapper
		height 100%
	&__inner
		height 100%
		display flex
		align-items flex-end
		justify-content center
		flex-direction column
		position relative
		padding-top 150px
	&__title
		max-width 523px
		text-align right
		margin-bottom 225px
		width 100%
	&__text
		max-width 477px
		text-align right
		width 100%
		font-size 20px
		line-height 30px
		font-weight 500
		opacity .5


.orbit
	position absolute
	top 0
	bottom 0
	left 11%
	margin auto
	//width 85px
	height 85px
	z-index 10
	&__inner
		display flex
		align-items center
		justify-content flex-start
	&__circle
		width 85px
		height 85px
		border-radius 50%
		background-color $white
		display flex
		align-items center
		justify-content center
		position relative
	&__letter
		div
			font-size 80px
			line-height 1
			font-weight 900
	&__item
		overflow hidden
		&:first-child
			padding-right 10px
		&:last-child
			padding-left 10px
	&__line
		position absolute
		&.big
			animation cycle 20s linear infinite
		&.small
			animation cycle 10s linear infinite
		svg
			overflow visible

.btns
	display flex
	align-items center
	justify-content center
	flex-wrap wrap
	width 100%
	margin-top 65px
	z-index 10
	position relative
	&.mt0
		margin-top 0
	&--start
		justify-content flex-start
	&__item
		font-size 15px
		font-family $custom-font
		line-height 25px
		text-transform uppercase
		min-width 256px
		height 55px
		padding 0 10px
		color $white
		margin 0 15px
		display flex
		align-items center
		justify-content center
		border 1px solid
		border-radius 20px
		&.bordered
			border-image url('../img/main/btn-border-bg.svg') 27 23 / 50px 30px 
		&.gradient
			color #061037
			background linear-gradient(to left, #F9CEDA, #978CF1 )
			border none
		&.m0
			margin-left 0
			margin-right 0

.how
	position relative
	margin-bottom 25px
	&__content
		margin-bottom 125px
	&__lines
		top -80%
		left 0
		right 0
		width 100%
		height 400px
		background-size cover
		background-position center 65%
		position absolute
		z-index 0
	&__img
		width 100%
		margin-bottom 30px
		position relative
		img
			position relative
			z-index 3
	&__planet
		position absolute
		left -8%
		top -70px
		z-index 2
		&:nth-child(2)
			top -150px
			left -160px
	&__slogan
		margin-top 130px
		font-size 30px
		line-height 45px
		max-width 475px
		position relative
		span
			position relative
			z-index 3
	&__info
		position relative
		display flex
		flex-direction column
		align-items center
		justify-content center
		max-width 520px
		font-size 20px
		line-height 30px
		font-weight 500
		text-align center
		z-index 1
		span
			margin-bottom 40px
			max-width 470px
		img
			position absolute
			top -20%
		a
			background -webkit-linear-gradient(45deg, #978CF1, #F9CEDA)
			-webkit-background-clip text
			-webkit-text-fill-color transparent
			position relative
			display inline-block
			&:after
				content ''
				position absolute
				width 100%
				height 1px
				background -webkit-linear-gradient(45deg, #978CF1, #F9CEDA)
				bottom 0
				left 0
				right 0
				margin auto
				
.features
	&__content
		margin-bottom 95px

.content
	p
		margin-bottom 35px
	h3
		margin-bottom 5px
	ul
		margin-bottom 20px
		padding-left 20px
	li
		font-size 20px
		line-height 40px
		font-weight 500
		list-style-image url('../img/icons/list-planet-icon.svg')
		color rgba(255,255,255,.5)
	&__wrapper
		display flex
		align-items flex-start
		justify-content space-between
		flex-wrap wrap
		&--start
			justify-content flex-start
			.content__block
				max-width 470px
				&:first-child
					margin-right 130px
		&--end
			align-items flex-end
			p
				margin-bottom 0
		&:not(:last-child)
			margin-bottom 95px
	&__block
		width calc(50% - 15px)
	&__title
		margin-bottom 30px

.merge
	position relative
	margin-bottom 125px
	&__title
		font-size 80px
		line-height 100px
		text-align right
	&__planet
		top 0
		left 0
		right 0
		width 100%
		height 400px
		background-size cover
		position absolute
		z-index 0
	&__info
		position relative
		max-width 355px
		width 100%
		height 170px
		display flex
		align-items center
		justify-content center
		font-family $custom-font
		font-weight 900
		z-index 1
		img
			position absolute
		span
			font-size 30px
			line-height 40px
			position relative
			z-index 2
	&__img
		img
			margin -250px auto 0
	.content__wrapper--end
		margin-bottom 25px

.token
	margin-bottom 225px
	&__title
		margin-bottom 40px
	&__subtitle
		display flex
		align-items center
		justify-content center
		img
			margin-right 5px
	&__wrapper
		//max-width 825px
		margin auto
		width 100%
		display flex
		align-items center
		justify-content space-between
		flex-wrap wrap
		margin-bottom 30px
	&__line
		margin-bottom 30px
	&__blocks
		display flex
		align-items flex-start
		justify-content space-between
		flex-wrap wrap
	&__block
		max-width 470px
		width 100%
	&__row
		display flex
		align-items center
		justify-content space-between
		&:not(:last-child)
			margin-bottom 10px
		span, strong
			opacity .7
		span
			font-size 20px
			line-height 35px
		div
			font-weight 500
			font-size 24px
			line-height 36px
.table
	margin-bottom 170px
	position relative
	&__bg
		top -5%
		left 0
		right 0
		width 100%
		height 400px
		background-size cover
		position absolute
		z-index 0
	&__title
		width 100%
		display flex
		align-items center
		justify-content flex-end
		margin-bottom 45px
	table
		width 100%
		font-size 20px
		line-height 30px
		td
			text-align center
			vertical-align middle
			padding 12px 0
			position relative
			font-weight bold
			z-index 2
			&:first-child
				font-weight 600
				opacity .7
			&:nth-child(2), 
			&:last-child
				text-transform uppercase
				font-size 30px
				line-height 45px
				font-family $custom-font
				background -webkit-linear-gradient(45deg, #978CF1, #F9CEDA)
				-webkit-background-clip text
				-webkit-text-fill-color transparent
				font-weight 500
		th
			opacity .7
			padding-bottom 10px
			vertical-align middle
			text-align center
			z-index 2
			position relative
			font-weight 400
		tr
			&:nth-child(2)
				td
					&:nth-child(3), 
					&:nth-child(4), 
					&:nth-child(5)
						font-weight 700
						font-weight bold
					&:nth-child(4), &:nth-child(5)
						position relative
						&:before
							content url('../img/main/table-cell-line.svg')
							position absolute
							bottom 0
							left 0
							right 0
					
							
			&:nth-child(9)
				td
					&:nth-child(3)
						font-weight 700
						font-weight bold
						position relative
						z-index 1
			&:nth-child(2)
				td
					&:nth-child(2),
					&:nth-child(6)
						position relative
						z-index 1
						&:after
							content url('../img/main/table-bg.svg')  
							position absolute
							top 0
							bottom 0
							left 0
							right 0
							margin auto
							z-index -1
					&:nth-child(4)
						position relative
						z-index 1
						&:after
							content url('../img/main/table-bg-mid.svg')  
							position absolute
							top 0
							bottom 0
							left 0
							right 0
							margin auto
							z-index -1

.zilla
	position relative
	margin-bottom 155px
	z-index 10
	&__planet
		top -5%
		left 0
		right 0
		width 100%
		height 440px
		background-size cover
		position absolute
		z-index 0
	&__title
		margin-bottom 80px
	&__subtitle
		text-align center
		max-width 530px
		margin auto
		font-size 30px
		line-height 45px
		font-family $custom-font
		margin-bottom 110px
	&__content
		display flex
		align-items flex-start
		justify-content flex-start
		flex-wrap wrap
		p
			font-size 15px
			line-height 25px
			&:not(:last-child)
				margin-bottom 20px
		strong
			color rgba(255,255,255,1)
	&__block
		width 50%
	&__img
		img
			margin -150px auto 0
	&__text
		text-align center
		margin auto
		max-width 240px
		font-size 20px
		line-height 30px
		font-weight bold
		margin -40px auto 20px
	&__socials
		display flex
		align-items center
		justify-content space-between
		max-width 180px
		margin auto
		z-index 99

.program
	margin-bottom 170px
	.content__block
		&:last-child
			position relative
			&:after
				content url('../img/main/program-planet.svg')
				position absolute
				top -30%
				right -10%
				z-index 0
	&__title
		text-align right
		font-size 50px
		line-height 65px
		position relative
		z-index 2
	.content__wrapper--start 
		.content__block
			max-width inherit
			&:first-child
				margin-right 0
	&__content
		p
			font-size 15px
			line-height 25px
	&__text
		font-size 20px
		line-height 30px
		margin-bottom 45px
		text-align center
		font-weight bold
	&__items
		display flex
		align-items center
		justify-content center
		flex-wrap wrap
		div
			width 50%
			display flex
			align-items center
			justify-content flex-start
			margin-bottom 35px
		img
			margin-right 15px
		span
			font-size 20px
			line-height 30px
			font-weight bold

.apply
	.btns
		margin-bottom 45px
	&__title
		max-width 770px
		margin-bottom 30px
	&__text
		max-width 630px
		margin-bottom 30px
		font-size 20px
		line-height 30px
		opacity .7

.socials
	&__title
		margin-bottom 45px
		font-size 30px
		line-height 45px
		font-family $custom-font
		text-align right
	&__items
		display flex
		align-items center
		justify-content space-between
		flex-wrap wrap
		a
			display block
			max-width 38px
			&:not(:last-child)
				margin-right 70px