/*==========  Desktop First  ==========*/

// /* Large Devices, Wide Screens */
// @media (max-width : 1200px)
// /* Medium Devices, Desktops */
// @media (max-width : 992px)
// /* Small Devices, Tablets */
// @media (max-width : 768px)
// /* Small Devices, Tablets */
// @media (max-width : 568px)
// /* Small Devices, Tablets */
// @media (max-width : 480px)
// /* Custom, iPhone Retina */
// @media (max-width : 380px)
/**/


@media (min-width 1440px)
    .zilla
        &__planet
            top 8%
            width 105%
    .footer
        &__bg
            top -100%

@media (max-width 1024px)
    .table
        &__inner
            overflow scroll
        table
            width 1024px

@media (min-width 1920px)
    .how
        &__lines
            height 500px
            top -110%
    .zilla
        &__planet
            top 2%
    .footer
        &__bg
            top -50%

@media (max-width 880px)
    .btns
        flex-direction column
        a
            width 100%
            margin 0 0 30px
            &:nth-child(1)
                order 2
            &:nth-child(2)
                order 3
            &:nth-child(3)
                order 1

@media (min-width 769px)
    .burger
        display none
    .merge
        &__img
            .mobile
                display none

@media (max-width 768px)
    .header
        &__menu
            display none
        &__wrapper
            padding 10px 0
    .intro
        &__title
            font-size 20px
            line-height 30px
        &__text
            text-align left
            font-size 18px
            line-height 29px
            opacity 1

        &__inner
            align-items flex-start
    .orbit
        left 0
        top -15%
        &__letter
            div
                font-size 45px
                line-height auto
        &__circle
            width 50px
            height 50px
            img
                max-width 30px
    .merge
        margin-bottom 200px
        &__img
            .desktop
                display none
            img
                margin -700px auto 0
        &__info
            width 100%
            max-width 100%
            &:first-child
                margin-bottom 500px
        &__content
            .content__wrapper
                &:last-child
                    flex-direction column
    .content
        &__block
            width 100%
            max-width 100% !important
            margin-right 0 !important

    .token
        &__wrapper, &__subtitle
            flex-direction column
            align-items center
            justify-content center
        &__subtitle
            &:not(:last-child)
                margin-bottom 20px
        &__blocks
            justify-content center
        &__block
            &:first-child
                .token__row
                    &:last-child
                        margin-bottom 10px
            &:nth-child(2n)
                .token__row
                    &:first-child
                        display none


    .zilla
        margin-bottom 50px
        &__subtitle
            margin-bottom 50px
        &__block
            width 100%
        &__img
            img
                margin auto
    .program
        margin-bottom 100px
        &__items
            flex-direction column
            div
                width 100%
            span
                font-size 15px
                line-height 25px
        &__title
            margin-bottom 32px
        .content__wrapper
            flex-direction column-reverse
            &:not(:last-child)
                margin-bottom 0

    .socials
        &__title
            text-align center

    .footer
        &__wrapper
            flex-direction column
            align-items center
            justify-content center
        &__logo
            margin-bottom 32px
        &__bottom
            flex-direction column
            align-items center
            justify-content center
        &__copyright
            order 2
            text-align center
        &__menu
            order 1
            flex-direction column
            padding-bottom 32px
            li
                margin-right 0 !important
                padding-right 0
                text-align center
                margin-bottom 15px
                &:after
                    display none


@media (max-width 480px)
    .nebula
        top -5%
        left -120%
        video
            width auto
            height auto
    .how
        &__info
            img
                top 20%
    .merge
        &__title
            font-size 60px
            line-height 80px
            margin-top 32px
            margin-bottom 32px
        &__planet
            top 0%
        .content__wrapper--end
            flex-direction column-reverse
    .section
        &__title
            font-size 30px
            line-height 45px
    .token
        margin-bottom 100px
        &__wrapper
            flex-direction column
        &__title
            text-align center
        // &__block
        //     &:not(:last-child)
        //         margin-bottom 30px
    .table
        margin-bottom 100px
        &__title
            justify-content center
    .zilla
        &__title
            text-align center
    .program
        &__title
            font-size 40px
            line-height 50px
            text-align center
        &__content
            margin-bottom 50px
    .apply
        &__title, &__text
            text-align center
    .socials
        &__items
            a
                margin-right 0 !important
    .footer
        &__wrapper
            margin-bottom 32px